import React, { Component } from 'react';
import { useStaticQuery, graphql, StaticQuery, Link} from "gatsby";
import { css } from "@emotion/core";
import { AuthUserContext } from '../components/Session';
import PortfolioProduct from "../components/portfolioproduct";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ReactDOM from 'react-dom';

function portfolioFormOpen(e) {
    var product = e.currentTarget.getAttribute('pbutton');
    var pForm = document.getElementById(product);
    pForm.classList.add('active');
    //document.getElementById('pForm').reset();
  }

function withProdData(WrappedComponent) {
  return props => (
    <StaticQuery
    query={graphql`
    {
        allWordpressPage(sort: {fields: acf___product_name}) {
            edges {
                node {
                    id
                    title
                    acf {
                        product_color
                        product_name
                        product_excerpt
                        brand_color
                        product_class {
                            label
                            value
                        }
                        product_list_headers_master {
                            color
                            background
                        }
                        product_list_headers_tether {
                            color
                            background
                        }
                    }
                    slug
                    excerpt
                    wordpress_id
                    parent_element {
                        id
                        wordpress_id
                        title
                        slug
                        acf {
                            brand_name
                        }
                        parent_element {
                            slug
                            parent_element {
                                slug
                            }
                        }
                    }
                }
            }
        }
    }
    `}
    render={data => <WrappedComponent {...props} prodData
    
    ={data} />}
    />
  );
}

const ProductLists = withProdData(props => (
    <React.Fragment>
    <div className="productList">
          <ExpansionPanel className="fCol12 productPanel">
            <ExpansionPanelSummary
              aria-controls={'product-panel'}
              id={'product-panel-header'}
            >
              <h3 className="categoryHeader" css={css` background: ${props.prodData.allWordpressPage.edges.filter(el => el.node.wordpress_id == props.masterID)[0].node.acf.product_list_headers_master.background} !important; color: ${props.prodData.allWordpressPage.edges.filter(el => el.node.wordpress_id == props.masterID)[0].node.acf.product_list_headers_master.color} !important;`}>{props.prodData.allWordpressPage.edges.filter(el => el.node.wordpress_id == props.masterID)[0].node.parent_element.title}</h3>

            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
            <ul>
        {props.prodData.allWordpressPage.edges.filter(el => el.node.parent_element != null).filter(el => el.node.parent_element.wordpress_id == props.masterID).sort((a, b) => a.node.title - b.node.title).map(product =>(
        <React.Fragment>
            <li key={'productList'+product.node.id} css={css` border-left: 9px solid ${product.node.acf.product_color}; `}>
            <Link className="productInfo" to={`/${product.node.parent_element.parent_element.parent_element.slug}/${product.node.parent_element.parent_element.slug}/${product.node.parent_element.slug}/products/${product.node.slug}/`}>
                <div>
                <h3 css={css` color: ${props.prodData.allWordpressPage.edges.filter(el => el.node.wordpress_id == props.masterID)[0].node.acf.brand_color}; `} dangerouslySetInnerHTML={{ __html: product.node.acf.product_name }} />
                <div class="description">{product.node.acf.product_excerpt}</div>
                </div>
            </Link>
            <AuthUserContext.Consumer>
                {authUser =>
                authUser ? (
                    <React.Fragment>
                        <div id={'mainPortfolioAddportfolioPopup'+product.node.id} className="portfolioAdd" pbutton={'portfolioPopup'+product.node.id} onClick={portfolioFormOpen}>
                            <span className="icon-star-unfill">
                            <span className="path1"></span>
                            <span className="path2"></span>
                            </span>
                            <span className="icon-star-full">
                            <span className="path1"></span>
                            <span className="path2"></span>
                            </span>
                            <span className="icon-select-bars"></span>
                        </div>
                        <PortfolioProduct key={'portfolioPopup'+product.node.id} productId={'portfolioPopup'+product.node.id} productTitle={product.node.acf.product_name.replace('<span class="reg"></span>','®')} brandName={product.node.parent_element.acf.brand_name.replace('<span class="reg"></span>','®')} productLink={`/${product.node.parent_element.parent_element.parent_element.slug}/${product.node.parent_element.parent_element.slug}/${product.node.parent_element.slug}/products/${product.node.slug}`}/>
                    </React.Fragment>
                ) : null
            }           
            </AuthUserContext.Consumer>
            </li>
        </React.Fragment>
        ))}
    </ul>
          </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>
        <div className="productList">
        <ExpansionPanel className="fCol12 productPanel">
          <ExpansionPanelSummary
            aria-controls={'product-panel'}
            id={'product-panel-header'}
          >
            <h3 className="categoryHeader" css={css` background: ${props.prodData.allWordpressPage.edges.filter(el => el.node.wordpress_id == props.masterID)[0].node.acf.product_list_headers_tether.background} !important; color: ${props.prodData.allWordpressPage.edges.filter(el => el.node.wordpress_id == props.masterID)[0].node.acf.product_list_headers_tether.color} !important;`}>{props.prodData.allWordpressPage.edges.filter(el => el.node.wordpress_id == props.tetherID)[0].node.parent_element.title}</h3>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
          <ul>
      {props.prodData.allWordpressPage.edges.filter(el => el.node.parent_element != null).filter(el => el.node.parent_element.wordpress_id == props.tetherID).sort((a, b) => a.node.title - b.node.title).map(product =>(
      <React.Fragment>
          <li key={'productList'+product.node.id} css={css` border-left: 9px solid ${product.node.acf.product_color}; `}>
          <Link className="productInfo" to={`/${product.node.parent_element.parent_element.parent_element.slug}/${product.node.parent_element.parent_element.slug}/${product.node.parent_element.slug}/products/${product.node.slug}/`}>
              <div>
              <h3 css={css` color: ${props.prodData.allWordpressPage.edges.filter(el => el.node.wordpress_id == props.masterID)[0].node.acf.brand_color}; `} dangerouslySetInnerHTML={{ __html: product.node.acf.product_name }} />
              <div class="description">{product.node.acf.product_excerpt}</div>
              </div>
          </Link>
          <AuthUserContext.Consumer>
                {authUser =>
                authUser ? (
                    <React.Fragment>
                        <div id={'mainPortfolioAddportfolioPopup'+product.node.id} className="portfolioAdd" pbutton={'portfolioPopup'+product.node.id} onClick={portfolioFormOpen}>
                            <span className="icon-star-unfill">
                            <span className="path1"></span>
                            <span className="path2"></span>
                            </span>
                            <span className="icon-star-full">
                            <span className="path1"></span>
                            <span className="path2"></span>
                            </span>
                            <span className="icon-select-bars"></span>
                        </div>
                        <PortfolioProduct key={'portfolioPopup'+product.node.id} productId={'portfolioPopup'+product.node.id} productTitle={product.node.acf.product_name.replace('<span class="reg"></span>','®')} brandName={product.node.parent_element.acf.brand_name.replace('<span class="reg"></span>','®')} productLink={`/${product.node.parent_element.parent_element.parent_element.slug}/${product.node.parent_element.parent_element.slug}/${product.node.parent_element.slug}/products/${product.node.slug}`}/>
                    </React.Fragment>
                ) : null
            }           
            </AuthUserContext.Consumer>
          </li>
      </React.Fragment>
      ))}
  </ul>
        </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
</React.Fragment>




    
));

class MasterTetherList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      masterID: this.props.masterID,
      tetherID: this.props.tetherID,
    };
  }
  componentDidMount() {
    
  }

  componentWillUnmount() {
    
    }


  

  

  render() {
    
    const {masterID, tetherID} = this.state;
    const {} = this.props;

    return (
        <ProductLists masterID={masterID} tetherID={tetherID}/>
    );
  }
}

export default MasterTetherList;