import React, { Component } from 'react';
import { useStaticQuery, graphql, StaticQuery} from "gatsby";
import { AuthUserContext } from './Session';
import { withFirebase } from './Firebase';
import { css } from "@emotion/core";
import Select from 'react-select';
import ReactDOM from 'react-dom';

const options = [];
const allOptions = [];
const formArray = [];
const selectedPortfolios =[];
const removedPortfolios=[];




function portfolioPage(e) {
  var portfolioForm = document.getElementById('portfolioForm');
  if(e.currentTarget.id === 'pFormNext') {
    if(portfolioForm.classList.contains('currentPage1')) {
      portfolioForm.classList.remove('currentPage1');
      portfolioForm.classList.add('currentPage2');
    } else if(portfolioForm.classList.contains('currentPage2')) {
      portfolioForm.classList.remove('currentPage2');
      portfolioForm.classList.add('currentPage3');
    }
  } else {
    if(portfolioForm.classList.contains('currentPage2')) {
      portfolioForm.classList.remove('currentPage2');
      portfolioForm.classList.add('currentPage1');
    } else if(portfolioForm.classList.contains('currentPage3')) {
      portfolioForm.classList.remove('currentPage3');
      portfolioForm.classList.add('currentPage2');
    }
  }
}
function portfolioFrameClose() {
  var portfolioIframe = document.getElementById('portfolioIframe');
  var portfolioIframeClose = document.getElementById('portfolioIframeClose');
  var portfolioNameOverlay = document.getElementById('portfolioNameOverlay');
  portfolioIframe.src = '';
  portfolioIframe.setAttribute("style", "display:none;");
  portfolioIframeClose.setAttribute("style", "display:none;");
  portfolioNameOverlay.setAttribute("style", "display:none;");
  portfolioNameOverlay.innerHTML = '';
}
/*function withProdData(WrappedComponent) {
  return props => (
    <StaticQuery
      query={graphql`
        {
          wp {
            pages {
              edges {
                node {
                  slug
                  childPages(where: {orderby: {field: TITLE, order: ASC}}) {
                    edges {
                      node {
                        title
                        pageId
                        slug
                        childPages(where: {orderby: {field: TITLE, order: ASC}}) {
                          edges {
                            node {
                              pageId
                              slug
                              childPages(where: {orderby: {field: TITLE, order: ASC}}, first: 20) {
                                edges {
                                  node {
                                    pageId
                                    slug
                                    Product_Page_Fields {
                                      productName
                                    }

                                  }
                                }
                              }
                              title
                              Brand_Page_Fields {
                                brandName
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  pageId
                  title
                }
              }
            }
          }
        
      }
    `}
    render={data => <WrappedComponent {...props} prodData
    
    ={data} />}
    />
  );
}

const ProductListing = withProdData(props => (
  <ul>
  
  <div className="">{formArray.categories.length=0}</div>
  {props.prodData.wp.pages.edges.map((top) =>(
    <React.Fragment>
      {top.node.childPages.edges.map((category,iC) =>(
        <React.Fragment>
          <div className="">{formArray.categories.push({"title": category.node.title,"pageID": category.node.pageId,"brands": [] })}</div>
          {category.node.childPages.edges.map((brand, iB) =>(
            <React.Fragment>
              <div className="brandName">{formArray.categories[iC].brands.push({"title": brand.node.Brand_Page_Fields.brandName.toString().replace('<span class="reg"></span>','®'),"page_id": brand.node.pageId, "slug": brand.node.slug, "products": [] })}</div>
              {brand.node.pageId}
              {brand.node.Brand_Page_Fields.brandName.toString().replace('<span class="reg"></span>','®')}
              {brand.node.childPages.edges.map((product, iP) =>(
                <React.Fragment>
                  <div className="">{formArray.categories[iC].brands[iB].products.push({"title":brand.node.Brand_Page_Fields.brandName.toString().replace('<span class="reg"></span>','®') , "label": product.node.Product_Page_Fields.productName.toString().replace('<span class="reg"></span>','®'),"value": '/'+top.node.slug+'/'+category.node.slug+'/'+brand.node.slug+'/products/'+product.node.slug})}</div>
                  {product.node.Product_Page_Fields.productName}<br/>
                  <div className="">{options.push({"label": product.node.Product_Page_Fields.productName.toString().replace('<span class="reg"></span>','®'),"value": product.node.pageId})}</div>
                </React.Fragment>
              ))}
            </React.Fragment>
          ))}
        </React.Fragment>
      ))}
    </React.Fragment>
  ))}
  </ul>
));
*/
class Collections extends Component {
  _initFirebase = false;

  constructor(props) {
    super(props);

    this.state = {
      text: '',
      portfolioName: '',
      loading: false,
      collections: [],
      limit: 21,
      value: 'select',
      inputValue: '',
      portfolios: '',
      selectedPortfolios:[],
      removedPortfolios:[],
      authUser: '',
      portCount: '',
      portfolioSubmits: 0,
      randomNumber: 0
    };
  }

  firebaseInit = () => {
    if (this.props.firebase && !this._initFirebase) {
      this._initFirebase = true;

      this.onListenForCollections();
    }
  };



  componentDidMount() {
    this.firebaseInit();
    const node = ReactDOM.findDOMNode(this);
    //this.childPort = node.querySelectorAll('.portfolioItem').length; 
    console.log(this.state.collections)
    setTimeout(() => {
      this.checkPorts();
    }, 1000);
    setTimeout(() => {
      this.checkPorts();
      console.log(this.props.productLink)
    }, 2000);
    setTimeout(() => {
      this.checkPorts();
    }, 3000);
    setTimeout(() => {
      this.checkPorts();
    }, 4000);
    setTimeout(() => {
      this.checkPorts();
    }, 5000);
    setTimeout(() => {
      this.checkPorts();
    }, 6000);
  }

  toggleMatching = (selected) => {
    //console.log(selected);
    const checkbox = document.getElementById(selected.currentTarget.id);
    const portBrands = JSON.parse(checkbox.getAttribute('portbrands'));
    const portfolioUid = checkbox.getAttribute('uid');
    const newProd = JSON.parse(checkbox.getAttribute('newproduct'));
    const uid = checkbox.getAttribute("uid");
    //const portBrandArray = JSON.parse(portBrands)
    //console.log(JSON.parse(newProd));
    //console.log(JSON.parse(portBrands));
    //console.log(portBrands);
    if (portBrands.some(e => e.value === newProd.value)) {
      //console.log(`Option selected:`, this.state.removedPortfolios);
      console.log(portBrands);
      const removeIndex = portBrands.findIndex(x => x.value === newProd.value);
      const updatedArray = portBrands.slice(0, removeIndex).concat(portBrands.slice(removeIndex + 1, portBrands.length))
      //const updatedArray = Array.from(new Set(portBrands.concat(filteredItems, newProd)));
      if (checkbox.checked === false) {
        this.setState(
          {removedPortfolios: Array.from(new Set(removedPortfolios.concat(this.state.removedPortfolios, {uid, updatedArray})))},
          () => console.log(`Option selected:`, this.state.removedPortfolios)
        );
      } else {
        const removeIndex = this.state.removedPortfolios.findIndex(x => x.uid === portfolioUid);
        //console.log(removeIndex);
        const filteredItems = this.state.removedPortfolios.slice(0, removeIndex).concat(this.state.removedPortfolios.slice(removeIndex + 1, this.state.removedPortfolios.length))
        this.setState(
          { removedPortfolios: filteredItems},
          () => console.log(`Option selected:`, this.state.removedPortfolios)
        );
      }
    } else {
      console.log('false');
      const updatedArray = Array.from(new Set(portBrands.concat(portBrands, newProd)));
      if (checkbox.checked === true) {
        this.setState(
          {selectedPortfolios: Array.from(new Set(selectedPortfolios.concat(this.state.selectedPortfolios, {uid, updatedArray})))},
          () => console.log(`Option selected:`, this.state.selectedPortfolios)
        );
      } else {
        const removeIndex = this.state.selectedPortfolios.findIndex(x => x.uid === portfolioUid);
        //console.log(removeIndex);
        const filteredItems = this.state.selectedPortfolios.slice(0, removeIndex).concat(this.state.selectedPortfolios.slice(removeIndex + 1, this.state.selectedPortfolios.length))
        this.setState(
          { selectedPortfolios: filteredItems},
          () => console.log(`Option selected:`, this.state.selectedPortfolios)
        );
      }
    }
    //console.log(updatedArray);
    //console.log(JSON.parse(portBrands));
    
    
  }

  portfolioFormClose = (e) => {
    var product = e.currentTarget.getAttribute('pbutton');
    var pForm = document.getElementById(product);
    pForm.classList.remove('active');
    //document.getElementById('pForm').reset();
  }

  componentDidUpdate = () => {
    this.firebaseInit();   
    const node = ReactDOM.findDOMNode(this);
    
    //console.log('all ports:', node.querySelectorAll('.portfolioItem').length);
    //console.log('archived ports:', node.querySelectorAll('.portfolioItem.archivedP').length);
    //console.log('active ports:', node.querySelectorAll('.portfolioItem.activeP').length);
    //this.childPort = node.querySelectorAll('.portfolioItem').length; 
    //this.setState({childPort: this.state.collections.length});
    this.childPort = this.state.collections.length;
    //this.childPortActive = node.querySelectorAll('.portfolioItem.activeP').length; 
    //this.childPortArchive = node.querySelectorAll('.portfolioItem.archivedP').length;    
  }


  onListenForCollections = () => {
    this.setState({ loading: true });

    //this.props.firebase
    this.unsubscribe = this.props.firebase
      .collections()
      //.orderByChild('createdAt')
      .orderBy('createdAt', 'desc')
      //.limitToLast(this.state.limit)
      .limit(this.state.limit)
      //.on('value', snapshot => {
      .onSnapshot(snapshot => {
        //const collectionObject = snapshot.val();
        if (snapshot.size) {
          let collections = [];
          snapshot.forEach(doc =>
            collections.push({ ...doc.data(), uid: doc.id }),
          );

        //if (collectionObject) {
          //const PortfoliosList = Object.keys(collectionObject).map(key => ({
            //...collectionObject[key],
            //uid: key,
          //}));

          this.setState({
            //collections: PortfoliosList,
            collections: collections.reverse(),
            loading: false,
          });
        } else {
          this.setState({ collections: null, loading: false });
        }
        console.log(this.state.collections)
      });
  }; 

  componentWillUnmount() {
    //this.props.firebase.collections().off();
    this.unsubscribe();
    
    
  }

  onAddPortfolioProduct = (event, selectedPortfolios, removedPortfolios, authUser, productTitle, productLink, brandName) => {
      
      const addProducts =  selectedPortfolios.map((portfolio, index) => {
        //console.log(this.props.firebase.collection(portfolio))
        //console.log(portfolio.uid)
        this.props.firebase.collection(portfolio.uid).update({
          portBrands: portfolio.updatedArray,
        });
        this.setState(
          {randomNumber: this.state.randomNumber+1}
        );
      });
      const removeProducts = removedPortfolios.map((portfolio, index) => {
        //console.log(this.props.firebase.collection(portfolio))
        //console.log(portfolio.uid)
        this.props.firebase.collection(portfolio.uid).update({
          portBrands: portfolio.updatedArray,
        })
        this.setState(
          {randomNumber: this.state.randomNumber+1}
        );
      });
      const prevent = event.preventDefault();
      const currentObject = this;
      Promise.all(addProducts, removeProducts, prevent).then(function() {
        //console.log('finished');
        setTimeout(() => {
          window.location.reload(true);
        }, 1000);
      });
  };
  onSubmited = () => {
    console.log('finished');
    this.setState(
      {portfolioSubmits: this.state.portfolioSubmits+1}
    );
  }
  onCreateCollection = (event, authUser) => {
    //this.props.firebase.collections().push({
    var filtered = this.state.allOptions.filter(function (el) {
      return el != null;
    });
    
    this.props.firebase.collections().add({
      text: this.state.text,
      portfolioName: this.state.portfolioName,
      portBrands: filtered,
      archived: false,
      userId: authUser.uid,
      userName: authUser.username,
      //createdAt: this.props.firebase.serverValue.TIMESTAMP,
      createdAt: this.props.firebase.fieldValue.serverTimestamp(),
      indexId: this.state.collections.filter(collections => collections.userId === authUser.uid ).length
    });

    this.setState({ text: '', portfolioName: '', portBrands: '', allOptions: '', selectedPortfolios: [], removedPortfolios: [] });

    event.preventDefault();
    var pForm = document.getElementById("portfolioForm");
    var createForm = document.getElementById("createPortfolio");

    
    createForm.reset();

    const selects = pForm.getElementsByClassName('product-selector');
  
    for (var i = 0; i < selects .length; i++) {
        selects[i].value = "";
    }
    setTimeout(function() {
      window.location.reload();
    }, 1000);
    
  };

  onEditCollection = (collection, text, portfolioName, portBrands) => {
    const { uid, ...collectionSnapshot } = collection;

    //this.props.firebase.collection(collection.uid).set({
    this.props.firebase.collection(collection.uid).update({
      ...collectionSnapshot,
      text,
      portfolioName,
      portBrands,
      //editedAt: this.props.firebase.serverValue.TIMESTAMP,
      editedAt: this.props.firebase.fieldValue.serverTimestamp(),
    });
  };

  /*onChangeArchiveTrue = (uid, collections, userId, indexId) => {
    this.props.firebase.collection(uid).update({
      archived: true
    });
    collections.filter(collection => {return collection.userId === userId, collection.indexId > indexId;}).map((item, index) => (
      this.props.firebase.collection(item.uid).update({
        indexId: item.indexId - 1
      })
    ));
  };

  onChangeArchiveFalse = uid => {
    this.props.firebase.collection(uid).update({
      archived: false,
      indexId: document.querySelectorAll('.activePI').length
    });
  };
  
  onChangeArchiveCollection = uid => {
    //this.props.firebase.collection(uid).remove();
    this.props.firebase.collection(uid).update({
      archived
    });
  };
  */

 /*onChangeOrder = (items) => {
  console.log(items);
  items.map((item, index) => (
    this.props.firebase.collection(item.value.uid).update({
      indexId: index,
    })
  ));
  this.setState(({collections}) => ({
    collections: collections
  }));
  console.log('finished')
    this.props.firebase.collection(uid).update({
    indexId: newIndex
  });
};*/

  onRemoveCollection = uid => {
    //this.props.firebase.collection(uid).remove();
    this.props.firebase.collection(uid).delete();
    this.setState({childPort: this.state.collections.length});
  };

  state = {
    selectedOption: [],
  };

  /*
  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({ [name]: value });
  };
  */

  onInputChange = (option, { action }) => {
    console.log(option, action);
    if (action === "input-change") {
      const optionLength = option.length;
      const inputValue = this.state.inputValue;
      const inputValueLength = inputValue.length;

      const newInputValue =
        optionLength < inputValueLength
          ? option
          : this.state.inputValue + option[option.length - 1];
      this.setState({
        inputValue: newInputValue
      });
    }
  };

  onChange = option => {
    this.setState({
      value: option
    });
  };


  
  handleInputChange = (value, e) => {
    if (e.action === "input-change") {
      this.setState({ value });

    }
  };

  checkPorts = () => {
    const pForm = document.getElementsByClassName("ogChecked"+this.props.productId);
    const portAdd = document.getElementById("mainPortfolioAdd"+this.props.productId);
    const portAddOg = document.getElementById("mainPortfolioAddOg"+this.props.productId);
    console.log(pForm.length);
    if (portAdd != null) {
      if (pForm.length > 0) {
        portAdd.classList.add('active');
      } else {
        portAdd.classList.remove('active');
      }
    }
    if (portAddOg != null) {
      if (pForm.length > 0) {
        portAddOg.classList.add('active');
      } else {
        portAddOg.classList.remove('active');
      }
    }
  } 

  handleChange = (selected, index) => {
    const removed = index.removedValue; 
    const clearedBrand = index.action;
    const clearedBrandName = index.name;
    console.log(index);
    if(clearedBrand === 'clear') {
      //console.log(clearedBrandName);
      //const newOptions = this.state.allOptions;
      var filtered = this.state.allOptions.filter(function (el) {
        return el != null;
      });
      //console.log(filtered);
      const result = filtered.filter(item => item.title != clearedBrandName);
      console.log(result);
      this.setState(
        { selectedOption: selected, allOptions: result },
        //() => console.log(`Option selected:`, this.state.selectedOption),
        //() => console.log(`Option selected:`, this.state.allOptions)
      );
    } else if(index.removedValue === null) {
      this.setState(
        { selectedOption: selected, allOptions: Array.from(new Set(allOptions.concat(this.state.allOptions, selected))) },
        //() => console.log(`Option selected:`, this.state.selectedOption),
        () => console.log(`Option selected:`, this.state.allOptions)
      );
    } else {
      const newOptions = this.state.allOptions;
      const removeIndex = newOptions.findIndex(x => x === removed);
      const filteredItems = newOptions.slice(0, removeIndex).concat(newOptions.slice(removeIndex + 1, newOptions.length))
      console.log(filteredItems);
      this.setState(
        { selectedOption: selected, allOptions: filteredItems},
      );
    }
  };

  

  render() {
    
  const { text, portfolioName, collections, loading, selectedOption, allOptions, childPort, portCount, tick, selectedPortfolios, removedPortfolios, portfolioSubmits, randomNumber} = this.state;
  const {productTitle, brandName, productLink, productId } = this.props
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <React.Fragment>
          <div id={productId} key={'ps'+productId+portfolioSubmits} className="flexContainer portfolioPopup" css={css`padding-top:10vh;`}>
              <button className="close" pbutton={productId} onClick={this.portfolioFormClose}/>
              <form
                id={"createPortfolio"+productId}
                onSubmit={event =>
                  this.onAddPortfolioProduct(event, this.state.selectedPortfolios, this.state.removedPortfolios, authUser,productTitle,productLink,brandName)
                }
              >
                <div id={'pFormStep1'+productId} className="pFormStep active">
                  <h3>Select a portfolio to add this product</h3>
                  {loading && <div>Loading ...</div>}
                  {collections.sort((a, b) => a.indexId - b.indexId).map((collection, index) => (
                    <React.Fragment>
                      {collection.userId === authUser.uid && 
                      <React.Fragment>
                        {!collection.portBrands.some(e => e.value === productLink) && 
                        <div id={'cbp'+productId+collection.uid} className="portfolioCheckbox" key={'cbp'+productId+collection.uid+portfolioSubmits}>
                          <input
                            key={'cb'+productId+collection.uid+portfolioSubmits}
                            className="form-control"
                            id={'checkbox'+productId+collection.uid+portfolioSubmits}
                            //id={'pCategory'+index}
                            //name={category.pageID}
                            uid={collection.uid}
                            portbrands={JSON.stringify(collection.portBrands)}
                            newproduct={'{"label":"'+productTitle+'", "title":"'+brandName+'", "value":"'+productLink+'"}'}
                            name={'collection'+index+productId+portfolioSubmits}
                            type="checkbox"
                            defaultChecked={false}
                            onChange={this.toggleMatching}
                          />
                          <label for={'checkbox'+productId+collection.uid+portfolioSubmits}>{collection.portfolioName}</label>
                        </div>
                        }
                        {collection.portBrands.some(e => e.value === productLink) && 
                        <div id={'cbpn'+productId+collection.uid+portfolioSubmits} className="portfolioCheckbox" key={'cbpn'+productId+collection.uid+portfolioSubmits}>
                          <input
                            key={'cbn'+productId+collection.uid+portfolioSubmits}
                            className={'form-control ogChecked'+productId}
                            id={'checkboxn'+productId+collection.uid+portfolioSubmits}
                            //id={'pCategory'+index}
                            //name={category.pageID}
                            uid={collection.uid}
                            portbrands={JSON.stringify(collection.portBrands)}
                            newproduct={'{"label":"'+productTitle+'", "title":"'+brandName+'", "value":"'+productLink+'"}'}
                            name={'collectionn'+index+productId+portfolioSubmits}
                            type="checkbox"
                            //checked={this.state.isChecked}
                            defaultChecked={true}
                            onChange={this.toggleMatching}
                          />
                          <label for={'checkboxn'+productId+collection.uid+portfolioSubmits}>{collection.portfolioName}</label>
                        </div>
                        }
                      </React.Fragment>
                      }
                    </React.Fragment>
                  ))}
                </div>
                <div id={'pFormButtons'+productId} className="flexContainer">
                  <button id={'portfolioAddSubmit'+productId} className="portfolioAddSubmit" type="submit">Save</button>
                </div>
                <div id={'createPortfolioLink'+productId} className="createPortfolioLink">
                  <a href="/portfolios"><button id={'portfolioCreate'+productId}>Go to Portfolios</button></a>
                </div>
              </form>
          </div>
          </React.Fragment>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

export default withFirebase(Collections);